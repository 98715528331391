import iconValentia from "../image/svg/ICON_fg_valentia.svg";
import iconCrozier from "../image/svg/ICON_fg_crozier.svg";
import iconBoltXl from "../image/svg/ICON_fg_boltXl.svg";
import iconHurler from "../image/svg/ICON_fg_hurler.svg";
import iconDocker from "../image/svg/ICON_fg_docker.svg";
import iconTruss from "../image/svg/ICON_fg_truss.svg";
import iconAugusta from "../image/svg/ICON_fg_augusta.svg";
import iconKinvara from "../image/svg/ICON_fg_kinvara.svg";

export const productData = [
    {
      name: "Valentia",
      ingredient1: "100g/l Fluroxypyr +",
      ingredient2: "2g/l Florasulam",
      role: "Admin",
      sloganDe: "Einzigartig in Mais – Top in Getreide",
      sloganEn: "Unique in corn - top in cereals",
      field1: "Corn",
      feld1: "Mais",
      styling1: "text-white bg-green-light",
      field2: "Cereals",
      feld2: "Getreide",
      styling2: "text-white bg-yellow-light",
      link: "./valentia",
      image: iconValentia,
      cat:"Cereals"
    },
    {
      name: "Crozier",
      ingredient1: "800g/l Prosulfocarb",
      ingredient2: "",
      sloganDe: "Doppeltes Potential für Ihr Feld",
      sloganEn: "Double potential for your field",
      field1: "Potato",
      feld1: "Kartoffeln",
      styling1: "text-white bg-brown-medium",
      field2: "Cereals",
      feld2: "Getreide",
      styling2: "text-white bg-yellow-light",
      link: "./crozier",
      image: iconCrozier,
    },
    {
      name: "Bolt",
      ingredient1: "250 g/l Prothioconazol",
      ingredient2: "",
      sloganDe: "Steigert die Gewinnkurve",
      sloganEn: "Boosts the profit curve",
      field1: "Rape",
      feld1: "Raps",
      styling1: "text-white bg-yellow-lighter",
      field2: "Cereals",
      feld2: "Getreide",
      styling2: "text-white bg-yellow-light",
      link: "./bolt",
      image: iconBoltXl,
    },
    {
      name: "Hurler",
      ingredient1: "200 g/l Fluroxypyr",
      ingredient2: "",
      sloganDe: "Gegen Unkräuter von früh bis spät",
      sloganEn: "Against weeds from early to late",
      field1: "Grassland",
      feld1: "Grünland",
      styling1: "text-white bg-green-lighter",
      field2: "Cereals",
      feld2: "Getreide",
      styling2: "text-white bg-yellow-light",
      link: "./hurler",
      image: iconHurler,
    },
    {
      name: "Docker",
      ingredient1: "150g/l Fluroxypyr",
      ingredient2: "150g/l Triclopyr",
      sloganDe: "Favorit im Grünland",
      sloganEn: "Favorite in grassland",
      field1: "Grassland",
      feld1: "Grünland",
      styling1: "text-white bg-green-lighter",
      field2: "",
      feld2: "",
      styling2: "text-white bg-yellow-light",
      link: "./docker",
      image: iconDocker,
    },
    {
      name: "Truss",
      ingredient1: "250g/l Trinexapac",
      ingredient2: "",
      sloganDe: "Beste Sicherung der Ertragspotentiale",
      sloganEn: "Best safeguarding of earnings potential",
      field1: "Creals",
      feld1: "Getreide",
      styling1: "text-white bg-yellow-light",
      field2: "",
      feld2: "",
      styling2: "text-white bg-yellow-light",
      link: "./truss",
      image: iconTruss,
    },
    {
      name: "Augusta",
      ingredient1: "250g/l Azoxystrobin",
      ingredient2: "",
      sloganDe: "Azoxystrobin – breit, kraftvoll, sicher",
      sloganEn: "Azoxystrobin - broad, powerful, safe",
      field1: "Creals",
      feld1: "Getreide",
      styling1: "text-white bg-yellow-light",
      field2: "Potato",
      feld2: "Kartoffel",
      styling2: "text-white bg-brown-medium",
      link: "./augusta",
      image: iconAugusta,
    },
    {
      name: "Kinvara",
      ingredient1: "28g/l Clopyralid, 50g/l Fluroxypyr,",
      ingredient2: "233g/l MCPA",
      sloganDe: "Breit und Stark im Grünland & Getreide",
      sloganEn: "Broad and strong in grassland & cereals",
      field1: "Grassland",
      feld1: "Grünland",
      styling1: "text-white bg-green-lighter",
      field2: "Creals",
      feld2: "Getreide",
      styling2: "text-white bg-yellow-light",
      link: "./kinvara",
      image: iconKinvara,
    },
  ];