// App.js

import React from "react";

import "./App.css";
import Dashboard from "./components/dashboard";
import { Switch, Route } from "react-router-dom";

import Footer from './components/footer'

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.getLang = this.getLang.bind(this);
    this.state = {
      lang: "de",
      site: "index"
    };
  }
  getLang(language) {
    this.setState({
      lang: language,
    });
  }

  render() {
    return (
      <>
        <Switch>
          <Route exact path="/" render={(props) => (
              <Dashboard
                {...props}
                lang={this.state.lang}
                site='index'
                handleLang={this.getLang}
              />
            )}
          />
          <Route exact path="/valentia" render={(props) => (
              <Dashboard
                {...props}
                lang={this.state.lang}
                site='valentia'
                handleLang={this.getLang}
              />
            )}
          />
          <Route exact path="/crozier" render={(props) => (
              <Dashboard
                {...props}
                lang={this.state.lang}
                site='crozier'
                handleLang={this.getLang}
              />
            )}
          />
          <Route exact path="/bolt" render={(props) => (
              <Dashboard
                {...props}
                lang={this.state.lang}
                site='bolt'
                handleLang={this.getLang}
              />
            )}
          />
          <Route exact path="/hurler" render={(props) => (
              <Dashboard
                {...props}
                lang={this.state.lang}
                site='hurler'
                handleLang={this.getLang}
              />
            )}
          />
          <Route exact path="/docker" render={(props) => (
              <Dashboard
                {...props}
                lang={this.state.lang}
                site='docker'
                handleLang={this.getLang}
              />
            )}
          />
          <Route exact path="/truss" render={(props) => (
              <Dashboard
                {...props}
                lang={this.state.lang}
                site='truss'
                handleLang={this.getLang}
              />
            )}
          />
          <Route exact path="/augusta" render={(props) => (
              <Dashboard
                {...props}
                lang={this.state.lang}
                site='augusta'
                handleLang={this.getLang}
              />
            )}
          />
          <Route exact path="/kinvara" render={(props) => (
              <Dashboard
                {...props}
                lang={this.state.lang}
                site='kinvara'
                handleLang={this.getLang}
              />
            )}
          />
          <Route exact path="/product_dev" render={(props) => (
              <Dashboard
                {...props}
                lang={this.state.lang}
                site='product_dev'
                handleLang={this.getLang}
              />
            )}
          />
        </Switch>

        <Footer lang={this.state.lang} handleLang={this.getLang} />
      </>
    );
  }
}
