import React from "react";

import {  } from "@heroicons/react/solid";

import {
  DocumentDownloadIcon,
  CheckIcon,
  ThumbUpIcon,
  BeakerIcon,
  ShieldCheckIcon,
  PuzzleIcon,
  CogIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";


import ValentiaIntro from "../../image/svg/Valentia_Intro.svg";
import ValentiaIntroImg from "../../image/png/img_valentia-intro.png";
import ValentiaCorn from "../../image/png/valentia_corn.png";
import ValentiaCereals from "../../image/svg/ICON_fg_cereal.svg";
import ValentiaCerealsImg from "../../image/jpg/valentia_cereals.jpg";
import ValentiaCerealInfo from "../../image/svg/img_Valentia_creals.svg";
import ValentiaCornInfo from "../../image/svg/valentia_cornInfo.svg";
import Greencircle1 from "../../image/svg/green_circle_1.svg";
import Greencircle14 from "../../image/svg/green_circle_1-4.svg";
import Greencircle34 from "../../image/svg/green_circle_3-4.svg";
import Yellowcircle1 from "../../image/svg/yellow_circle_1.svg";
import Yellowcircle14 from "../../image/svg/yellow_circle_1-4.svg";
import YellowCircle34 from "../../image/svg/yellow_circle_3-4.svg";
import Greencircle0 from "../../image/svg/green_circle_0.svg";
import GreenFormulationImg from "../../image/svg/grueneFornulierung.svg";
import BromxynilAlternativ from "../../image/svg/bromxynilAlternativ.svg";
import imgResistence from "../../image/png/img_resistence.png";
import ValentiaPrePost from "../../image/jpg/img_valentiaControlle.JPG";

import valentiaFlyer from "../../image/pdf/2301_FG_Valentia_WEB.pdf";

import BarChart from "../../charts/barChart.tsx";
import BarChartPhyto from "../../charts/barChartPhyto.tsx";
import Divider from "../elements/divider";

import berndBund from "../../image/jpg/formgroup-berndBund.jpg";
import berndBelitz from "../../image/jpg/formgroup-berndbelitz.JPG";
import hugoSchweers from "../../image/jpg/formgroup-hugoschweers.jpg";
import bernhardOverberg from "../../image/jpg/formgroup-bernhardoverberg.jpg";


const summary = [
  {
    id: 1,
    contentDe: "TOP Leistungsklasse im Mais  gegen Knötericharten, Kamille, Klettenlabkraut, Kartoffeldurchwuchs, und Winden ",
    contentEn: "TOP performance class in corn against knotweed species, chamomile, burdock ragwort, potato browse, and bindweed.",
    target: "",
    href: "",
    icon: BeakerIcon,
    iconBackground: "bg-green-lighter",
  },
  // {
  //   id: 2,
  //   contentDe: "TOP Leistungsklasse im Getreide zur Nachbehandlung gegen Klettenlabkraut",
  //   contentEn: "TOP performance class in cereals for post-treatment against burdock ragwort.",
  //   target: "",
  //   href: "",
  //   icon: CogIcon,
  //   iconBackground: "bg-green-lighter",
  // },
  {
    id: 3,
    contentDe: "TOP Leistungsklasse im  Umwelt-, Nützlings- und Handlingsprofil sowie angenehmer Geruch durch GRÜNE FORMULIERUNG",
    contentEn: "TOP performance class in environmental, beneficial and handling profile as well as pleasant odor due to GREEN FORMULATION.",
    target: "",
    href: "",
    icon: ShieldCheckIcon,
    iconBackground: "bg-green-lighter",
  },
  {
    id: 4,
    contentDe: "Wirkungsabsicherung und Resistenzprophylaxe für alle Herbizidstrategien",
    contentEn: "Efficacy protection and resistance prophylaxis for all herbicide strategies.",
    target: "",
    href: "",
    icon: ThumbUpIcon,
    iconBackground: "bg-green-lighter",
  },
  {
    id: 5,
    contentDe: "VALENTIA ist durch die GRÜNE FORMULIERUNG sehr verträglich - Fluroxypyr ist nicht Fluroxypyr",
    contentEn: "VALENTIA is very compatible due to the GREEN FORMULATION - Fluroxypyr is not Fluroxypyr.",
    target: "",
    href: "",
    icon: CogIcon,
    iconBackground: "bg-green-lighter",
  },
  {
    id: 6,
    contentDe: "VALENTIA ist hochwirksam und trägt zur Reduzierung von Wirkstoffmengen bei (Green Deal)",
    contentEn: "VALENTIA is highly effective and contributes to the reduction of active ingredient quantities (Green Deal).",
    target: "",
    href: "",
    icon: PuzzleIcon,
    iconBackground: "bg-green-lighter",
  },
  {
    id: 7,
    contentDe: "VALENTIA - das ideal günstige Universalherbizid für Mais und Getreide",
    contentEn: "VALENTIA - the ideal low-priced universal herbicide for corn and cereals.",
    target: "",
    href: "",
    icon: CheckIcon,
    iconBackground: "bg-green-lighter",
  },
];

const highlightsCereals = [
  {
    descriptionDe: "Geniale Kombination unterschiedlicher Wirkmechanismen",
    descriptionEn: "Ingenious combination of different mechanisms of action",
  },
  {
    descriptionDe:
      "Herausragende Wirkung gegen Klettenlabkraut jeder Größe – inkl. Spätbehandlung",
    descriptionEn:
      "Outstanding effect against goosegrass of any size - incl. late treatment",
  },
  {
    descriptionDe: "Langer Anwendungszeitraum bis BBCH 45",
    descriptionEn: "Long application period until BBCH 45",
  },
  {
    descriptionDe: "Sehr gut mischbar und verträglich",
    descriptionEn: "Very well miscible and compatible",
  },
  {
    descriptionDe: "Verlässlich, unkompliziert, schnell und breit wirksam",
    descriptionEn: "Reliable, uncomplicated, fast and broadly effective",
  },
  {
    descriptionDe: "Besonders stark auf Kamille, Knöterich, Winde und mehr",
    descriptionEn: "Particularly strong on camomile, knotweed, bindweed and more.",
  },
  {
    descriptionDe: "Der starke Partner für Komplettlösungen",
    descriptionEn: "The strong partner for a complete solution",
  },
];

const corntable = [
  {
    nameDe: "Kamille",
    nameEn: "Camomile",
    circle1: Greencircle1,
    circle2: Greencircle1,
    image: "",
  },
  {
    nameDe: "Klettenlabkraut",
    nameEn: "Burdock cabbage",
    circle1: Greencircle1,
    circle2: Greencircle1,
    image: "",
  },
  {
    nameDe: "Knöterich-Arten*",
    nameEn: "Knotweed species*",
    circle1: Greencircle1,
    circle2: Greencircle1,
    image: "",
  },
  {
    nameDe: "Durchw.-Kartoffeln",
    nameEn: "Through-grown potatoes",
    circle1: Greencircle1,
    circle2: Greencircle1,
    image: "",
  },
  {
    nameDe: "Ackerwinde*",
    nameEn: "Field bindweed*",
    circle1: Greencircle1,
    circle2: Greencircle1,
    image: "",
  },
  {
    nameDe: "Schw. Nachtschatten",
    nameEn: "Black nightshade",
    circle1: Greencircle1,
    circle2: Greencircle1,
    image: "",
  },
  {
    nameDe: "Franzosenkraut",
    nameEn: "French herb",
    circle1: Greencircle1,
    circle2: Greencircle1,
    image: "",
  },
  {
    nameDe: "Vogelmiere",
    nameEn: "Chickweed",
    circle1: Greencircle1,
    circle2: Greencircle1,
    image: "",
  },
  {
    nameDe: "Ausfallraps ",
    nameEn: "Drop-out rapeseed",
    circle1: Greencircle1,
    circle2: Greencircle1,
    image: "",
  },
  {
    nameDe: "Amarant",
    nameEn: "Amaranth",
    circle1: Greencircle14,
    circle2: Greencircle1,
    image: "",
  },
  {
    nameDe: "Gänsefuß",
    nameEn: "Goosefoot",
    circle1: Greencircle14,
    circle2: Greencircle1,
    image: "",
  },
  {
    nameDe: "Ehrenpreis",
    nameEn: "Veronica",
    circle1: Greencircle14,
    circle2: Greencircle1,
    image: "",
  },
  {
    nameDe: "Hirse",
    nameEn: "Millet",
    circle1: Greencircle0,
    circle2: Greencircle1,
    image: "",
  },

  // More people...
];
const cerealstable = [
  {
    nameDe: "Kamille",
    nameEn: "Camomile",
    circle1: Yellowcircle1,
    circle2: Yellowcircle1,
    circle3: Yellowcircle1,
    image: "",
  },
  {
    nameDe: "Klettenlabkraut",
    nameEn: "Goosegrass",
    circle1: Yellowcircle1,
    circle2: Yellowcircle1,
    circle3: Yellowcircle1,
    image: "",
  },
  {
    nameDe: "Vogelmiere",
    nameEn: "Chickweed",
    circle1: Yellowcircle1,
    circle2: Yellowcircle1,
    circle3: Yellowcircle1,
    image: "",
  },
  {
    nameDe: "Knötericharten*",
    nameEn: "Knotweed species*",
    circle1: Yellowcircle1,
    circle2: Yellowcircle1,
    circle3: Yellowcircle1,
    image: "",
  },
  {
    nameDe: "Ackerwinde*",
    nameEn: "Field bindweed*",
    circle1: Yellowcircle1,
    circle2: Yellowcircle1,
    circle3: Yellowcircle1,
    image: "",
  },
  {
    nameDe: "Ausfallraps",
    nameEn: "Fall-out rapeseed",
    circle1: Yellowcircle1,
    circle2: Yellowcircle1,
    circle3: Yellowcircle1,
    image: "",
  },
  {
    nameDe: "Kornblume",
    nameEn: "Cornflower",
    circle1: YellowCircle34,
    circle2: Yellowcircle1,
    circle3: Yellowcircle1,
    image: "",
  },
  {
    nameDe: "Erdrauch",
    nameEn: "Fumitory",
    circle1: YellowCircle34,
    circle2: YellowCircle34,
    circle3: YellowCircle34,
    image: "",
  },
  {
    nameDe: "Taubnessel",
    nameEn: "Deadnettle",
    circle1: Yellowcircle14,
    circle2: Yellowcircle14,
    circle3: Yellowcircle1,
    image: "",
  },
  {
    nameDe: "Stiefmütterchen",
    nameEn: "Pansies",
    circle1: Greencircle0,
    circle2: Yellowcircle14,
    circle3: Yellowcircle1,
    image: "",
  },
  {
    nameDe: "Ehrenpreis",
    nameEn: "Veronica",
    circle1: Yellowcircle14,
    circle2: YellowCircle34,
    circle3: YellowCircle34,
    image: "",
  },
  {
    nameDe: "Acker-Distel*",
    nameEn: "Field thistle*",
    circle1: Yellowcircle14,
    circle2: Yellowcircle1,
    circle3: YellowCircle34,
    image: "",
  },

  // More people...
];

const transferFeatures = [
  {
    id: 1,
    name: 'Valentia 0,6-0,8 l/ha + Triketone / SU´s / Isoxaflutol',
    descriptionDe:
      'Wirkungsabsicherung bei Knöterich- und Windenarten, Klettenlabkraut, Kamille, Amarant, Nachtschatten, Ampfer, Disteln',
    descriptionEn:
      'Effect protection for knotweed and bindweed species, burdock ragwort, chamomile, amaranth, nightshade, dock, thistles.',
    icon: BeakerIcon,
    iconColor: 'bg-green-medium',
  },
  {
    id: 2,
    name: 'Valentia 1,0 l/ha + Mesotrione / Nicosulfuron ',
    descriptionDe:
      'Breite Mischverunkrautung inkl. Hirse',
    descriptionEn:
      'Wide mixed weed incl. millet',
    icon: BeakerIcon,
    iconColor: 'bg-green-light',
  },
  {
    id: 3,
    name: 'Valentia 1,5 l/ha',
    descriptionDe:
    'Kartoffeldurchwuchs, Ampfer, Disteln',
    descriptionEn:
    'Potato growth, dock, thistles',
    icon: BeakerIcon,
    iconColor: 'bg-green-lighter',
  },
]

const glanceFeatures= [
  {
    nameDe: 'Fluroxypyr 100g/l, Florasulam 2g/l',
    nameEn: 'Fluroxypyr 100g/l, Florasulam 2g/l',
    icon: BeakerIcon,
  },
  {
    nameDe: 'Mais / Winterweizen / Wintergerste',
    nameEn: 'Corn / Winter wheat / Winter barley',
    icon: BeakerIcon,
  },
  {
    nameDe: 'MOA: Auxine / ALS',
    nameEn: 'MOA: Auxine / ALS',
    icon: BeakerIcon,
  },
  {
    nameDe: 'Resistenzgruppe: 4 / 2',
    nameEn: 'Resistance group: 4 / 2',
    icon: ShieldCheckIcon,
  },
  {
    nameDe: 'Keine Hangauflage, Gewässerabstand 0 Meter (90% Abdriftkl.)',
    nameEn: 'No slope, water distance 0 meters (90% drift cl.)',
    icon: CogIcon,
  },
  {
    nameDe: 'Suspoemulsion (SE)',
    nameEn: 'Suspoemulsion (SE)',
    icon: BeakerIcon,
  },
  {
    nameDe: 'Zulassungsnummer 00A191-00   zugelassen bis 31.12.2025',
    nameEn: 'Approval number 00A191-00 approved until 31.12.2025',
    icon: BeakerIcon,
  },
  {
    nameDe: '4x5l (800l Palette)',
    nameEn: '4x5l (800l pallet)',
    icon: BeakerIcon,
  },
]

const people = [
  {
    name: 'Hugo Schweers',
    roleDe: "35+ Jahre Erfahrung im Agrobusiness + ex. Vorstandsmitglied Agravis/Nufarm",
    roleEn: '35+ years of experience in agribusiness + ex. Boardmember Agravis/Nufarm',
    imageUrl: hugoSchweers,
    Mail: 'mailto:hugo.schweers@formgroup.io',
    linkedinUrl: 'https://www.linkedin.com/in/hugo-schweers-bb449588/',
  },
  {
    name: 'Bernd Belitz',
    roleDe: "30+ Jahre Erfahrung im Agrobusiness",
    roleEn: '30+ years of experience in agribusiness',
    imageUrl: berndBelitz,
    Mail: 'mailto:bernd.belitz@formgroup.io',
    linkedinUrl: 'https://www.linkedin.com/in/bernd-belitz-768b9b72/',
  },
  {
    name: 'Dr. Hans-Bernhard Overberg',
    roleDe: "20+ Jahre Erfahrung im Agrobusiness",
    roleEn: '20+ years of experience in agribusiness',
    imageUrl: bernhardOverberg,
    Mail: 'mailto:hansbernhard.overberg@formgroup.io',
    linkedinUrl: 'https://www.linkedin.com/in/dr-hans-bernhard-overberg/',
  },
  {
    name: 'Bernd K. Bund',
    roleDe: "25+ Jahre Erfahrung im Agrobusiness und selbstständiger Landwirt",
    roleEn: '25+ years of experience in agribusiness and self-employed farmer',
    imageUrl: berndBund,
    Mail: 'mailto:bernd.bund@formgroup.io',
    linkedinUrl: '',
  },
  // More people...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function valentia(props) {
  return (
    <>
      <section id="header">
        <div className="pt-16 pb-36 bg-gradient-to-br from-green-black via-green-darker to-green-dark sm:pt-24 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-24 mt-16">
                  <a
                    href="#Valentia Corn"
                    className="inline-flex items-center text-white bg-black rounded-full sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                  >
                    {" "}
                    <span className="px-3 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-light rounded-full">
                      {props.lang === "de" ? "Mais" : "Corn"}
                    </span>
                  </a>

                  <a
                    href="#ValentiaCereal"
                    className="inline-flex items-center text-white bg-black rounded-full sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                  >
                    <span className="px-3 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-yellow-light rounded-full">
                      {props.lang === "de" ? "Getreide" : "Cereals"}
                    </span>
                  </a>
                  <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="block">VALENTIA</span>
                  </h1>
                  <p className="mt-3 text-lg text-green-light font-extrabold sm:mt-5 sm:text-lg lg:text-xl uppercase">
                    {props.lang === "de"
                      ? "Einzigartig in Mais – Top in Getreide"
                      : "Unique in corn - top in cereals"}
                  </p>
                  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-lg lg:text-xl">
                    {props.lang === "de"
                      ? "VALENTIA - MACHT den UNTERSCHIED"
                      : "VALENTIA - MAKES THE DIFFERENCE"}
                  </p>
                  <div className="mt-8">
                    <div className="inline-flex rounded-md shadow">
                      <a
                        href={valentiaFlyer}
                        className="inline-flex items-center justify-center px-5 py-3 border border-transparent uppercase text-base font-bold rounded-md text-white bg-green-light hover:bg-green-lighter"
                      >
                        {props.lang === "de"
                          ? "Download Valentia Broschüre"
                          : "Download Valentia Flyer"}
                        <DocumentDownloadIcon
                          className="-mr-1 ml-3 h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 -mb-32 sm:-mb-48 lg:m-0 lg:relative">
                <div className="mx-auto max-w-md grid place-items-center sm:px-8 sm:max-w-2xl lg:max-w-none lg:px-0">
                  <img
                    className="w-full z-40 justify-item-center lg:absolute lg:inset-y-32 lg:left-16 lg:-ml-16 lg:h-96 lg:w-auto lg:max-w-none"
                    src={ValentiaIntro}
                    alt="Valentia Gebinde/Can"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="navbar" className="sticky top-0 z-50">
        <div className=" overflow-x-auto whitespace-nowrap border-b transition duration-100 border-transparent bg-gradient-to-br from-green-darker to-green-dark">
          <div className="mx-auto flex max-w-container justify-between space-x-8 py-4 text-sm leading-6 text-slate-600">
            <div className="flex space-x-4 pl-4 sm:pl-6 lg:pl-8 text-white">
              <a
                className="px-3 py-1 text-lg font-semibold"
                href="#header"
              >
                VALENTIA
              </a>
              <a
                className="border-solid border border-white px-3 py-1 rounded-full hover:border-green-light focus:border-green-light hover:text-green-light hover:font-semibold focus:text-green-light"
                href="#ValentiaSummary"
              >
                {props.lang === "de" ? "Auf einen Blick" : "At a glance"}
              </a>
              <a
                className="border-solid border border-white px-3 py-1 rounded-full hover:border-green-light focus:border-green-light hover:text-green-light hover:font-semibold focus:text-green-light"
                href="#GreenFormulation"
              >
                {props.lang === "de"
                  ? "GRÜNE FORMULIERUNG"
                  : "GREEN FORMULATION"}
              </a>
              <a
                className="border-solid border border-white pl-3 py-1 rounded-full hover:border-green-light focus:border-green-light hover:text-green-light hover:font-semibold focus:text-green-light"
                href="#SpectrumCorn"
              >
                {props.lang === "de"
                  ? "Wirkungsspektrum"
                  : "Spectrum of action"}
                <span className="px-2 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-light rounded-full">
                  {props.lang === "de" ? "Mais" : "Corn"}
                </span>
              </a>
              <a
                className="border-solid border border-white pl-3 py-1 rounded-full hover:border-green-light focus:border-green-light hover:text-green-light hover:font-semibold focus:text-green-light"
                href="#RecommendationCorn"
              >
                {props.lang === "de" ? "Empfehlung" : "Recommendation"}
                <span className="px-2 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-light rounded-full">
                  {props.lang === "de" ? "Mais" : "Corn"}
                </span>
              </a>
              <a
                className="border-solid border border-white pl-3 py-1 rounded-full hover:border-green-light focus:border-green-light hover:text-green-light hover:font-semibold focus:text-green-light"
                href="#CompatibilityCorn"
              >
                {props.lang === "de" ? "Verträglichkeit" : "Compatibility"}
                <span className="px-2 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-light rounded-full">
                  {props.lang === "de" ? "Mais" : "Corn"}
                </span>
              </a>
              <a
                className="border-solid border border-white pl-3 py-1 rounded-full hover:border-green-light focus:border-green-light hover:text-green-light hover:font-semibold focus:text-green-light"
                href="#resistence"
              >
                {props.lang === "de" ? "Resistenzabwehr" : "Resistance defense"}
                <span className="px-2 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-light rounded-full">
                  {props.lang === "de" ? "Mais" : "Corn"}
                </span>
              </a>
              <a
                className="border-solid border border-white pl-3 py-1 rounded-full hover:border-green-light focus:border-green-light hover:text-green-light hover:font-semibold focus:text-green-light"
                href="#bromoxynil-alternativ"
              >
                {props.lang === "de"
                  ? "„Bromoxynil Ersatz“"
                  : "“Bromoxynil Replacement“"}
                <span className="px-2 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-light rounded-full">
                  {props.lang === "de" ? "Mais" : "Corn"}
                </span>
              </a>
              <a
                className="border-solid border border-white pl-3 py-1 rounded-full hover:border-yellow-light focus:border-yellow-light hover:text-yellow-light hover:font-semibold focus:text-yellow-light"
                href="#ValentiaCereal"
              >
                {props.lang === "de"
                  ? "VALENTIA kann mehr"
                  : "VALENTIA can do more"}
                <span className="px-2 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-yellow-light rounded-full">
                  {props.lang === "de" ? "Getreide" : "Cereals"}
                </span>
              </a>
              <a
                className="border-solid border border-white px-3 py-1 rounded-full hover:border-green-light focus:border-green-light hover:text-green-light hover:font-semibold focus:text-green-light"
                href="#ContactValentia"
              >
                {props.lang === "de" ? 'agroform-Team' : "agroform-Team"}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="ValentiaSummary">
        <div className="relative bg-white pt-12">
          <div className="lg:absolute lg:inset-0 lg:inline hidden">
            <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
              <img
                className="h-48 w-full object-cover lg:absolute lg:h-full"
                src={ValentiaIntroImg}
                alt="Valentia Corn"
              />
            </div>
          </div>
          <div className="relative px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
            <div className="lg:col-start-2 lg:pl-8">
              <div className="text-base ">
                <h2 className="leading-6 text-green-medium font-semibold tracking-wide uppercase">
                  Valentia
                </h2>
                <h3 className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  {props.lang === "de"
                    ? "Alle Vorteile auf einen Blick"
                    : "All advantages at a glance"}
                </h3>
              </div>
              <div>
                <div className="flow-root">
                  <ul className="mt-8">
                    {summary.map((event, eventIdx) => (
                      <li key={event.id}>
                        <div className="relative pb-8">
                          {eventIdx !== summary.length - 1 ? (
                            <span
                              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex space-x-3">
                            <div>
                              <span
                                className={classNames(
                                  event.iconBackground,
                                  "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                                )}
                              >
                                <event.icon
                                  className="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                              <div>
                                <p className="text-sm text-black">
                                  {props.lang === "de"
                                    ? event.contentDe
                                    : event.contentEn}
                                  <a
                                    href={event.href}
                                    className="font-medium text-gray-900"
                                  >
                                    {event.target}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative bg-white pt-24">
          <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
            <h2 className="leading-6 text-green-medium font-semibold tracking-wide uppercase">
              Valentia
            </h2>
            <h3 className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {props.lang === "de" ? "Auf einen Blick" : "At a glance"}
            </h3>
            <div className="mt-20">
              <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 lg:grid-cols-4">
                {glanceFeatures.map((feature) => (
                  <div key={feature.name} className="pt-6">
                    <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8 h-48 sm:36">
                      <div className="-mt-6">
                        <div>
                          <span className="inline-flex items-center justify-center rounded-xl bg-green-light p-3 ">
                            <feature.icon
                              className="h-8 w-8 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <h3 className="mt-8 text-md font-semibold tracking-tight text-gray-900">
                          {props.lang === "de"
                            ? feature.nameDe
                            : feature.nameEn}
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="GreenFormulation">
        <Divider text={"GREEN FORMULATION"} />

        <div className="bg-white py-24 sm:py-32 lg:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="sm:text-center">
              <h2 className="text-lg font-semibold leading-8 text-green-light">
                {props.lang === "de"
                  ? "GRÜNE FORMULIERUNG"
                  : "GREEN FORMULATION"}
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:px-48">
                {props.lang === "de"
                  ? "Umwelt- und Anwenderschutz auf hohem Niveau"
                  : "Environmental and user protection at a high level"}
              </p>
              <img
                className="h-full w-full object-cover mt-12"
                src={GreenFormulationImg}
                alt="GRÜNE FORMULIERUNG"
              />
            </div>

            <div className="lg:grid lg:grid-cols-2 lg:gap-6 mt-12">
              <div className="prose prose-lg prose-indigo text-gray-500 lg:max-w-none lg:px-8">
                <p>
                  {props.lang === "de"
                    ? "VALENTIA ist auf Basis von Pinenen formuliert – einem Stoff wie er auch in Nadelbäumen vorkommt. Hierdurch wird die günstige Umwelt- und Gefahreneinstufung, aber auch die gute Verträglichkeit erzielt."
                    : "VALENTIA is formulated on the basis of pinene - a substance also found in conifers. This achieves the favorable environmental and hazard classification, but also the good compatibility."}
                </p>
              </div>
              <div className="prose prose-lg prose-indigo mt-6 text-gray-500 lg:mt-0 lg:px-8">
                <p>
                  {props.lang === "de"
                    ? "VALENTIA hat im Vergleich zu ähnlichen Produkten eine hohe Nützlingsschonung, eine gute Gefahreneinstufung, günstige Abstandseinstufung und bietet einen hohen Anwenderschutz. Außerdem fällt im Vergleich zu den meisten Pflanzenschutzmitteln der angenehme Geruch von VALENTIA auf."
                    : "Compared to similar products, VALENTIA has a high level of protection for beneficial insects, a good hazard classification, favorable distance classification and offers a high level of user protection. In addition, VALENTIA's pleasant odor is noticeable compared to most crop protection products."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Divider text={"Wirkungsprektrum"} />
      <section id="SpectrumCorn" className="bg-green-medium py-24">
        <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="">
            <a
              href="/"
              className="inline-flex items-center text-white bg-gray-100 rounded-full px-1 py-1 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
            >
              <span className="px-3 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-light rounded-full">
                {props.lang === "de" ? "Mais" : "Corn"}
              </span>
            </a>
            <h3 className="mt-2 text-lg leading-8 font-extrabold tracking-tight text-white sm:text-lg">
              {props.lang === "de" ? "Wirkungsspektrum" : "Spectrum of action"}
            </h3>
            <h3 className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-green-dark sm:text-3xl">
              {props.lang === "de"
                ? "VALENTIA - Einzigartig in Mais"
                : "VALENTIA - Unique in corn"}
            </h3>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-6 mt-6">
            <div className="prose prose-lg text-white lg:max-w-none">
              <p>
                {props.lang === "de"
                  ? "Kaum eine große Ackerbaukultur steht bei der Unkrautbekämpfung vor so großen Herausforderungen wie der Mais. Steigende Umweltanforderungen, Resistenzgefahren und zulassungsseitig reduzierter Einsatz verschiedener wichtiger Wirkstoffe sind Beispiele der steigenden Herausforderungen."
                  : "Hardly any other major arable crop faces such major challenges in weed control as corn. Increasing environmental requirements, resistance risks and reduced use of various important active ingredients on the approval side are examples of the growing challenges."}
              </p>
            </div>
            <div className="mt-6 prose prose-lg text-white lg:mt-0">
              <p>
                {props.lang === "de"
                  ? "Die Einschränkungen bei Terbutyhylazin, S-Metolachlor oder der Wegfall von Bromoxynil, machen den Einsatz neuer Behandlungsstrategien ausgerichtet auf wichtige Leitunkräuter wie Knötericharten, Winden, Schwarzer Nachschatten, Kamille,  Gänsefuß oder auch Franzosenkraut notwendig. Mit VALENTIA steht ein innovativer Baustein zur Verfügung, der die Wirkungslücken in jeder Mischung schließt."
                  : "The limitations of terbutyhylazine, s-metolachlor or the discontinuation of bromoxynil, necessitate the use of new treatment strategies targeted at key stalk weeds such as knotweed species, bindweed, black nightshade, chamomile, goosefoot or even franciscus. VALENTIA is an innovative component that closes the gaps in effectiveness in any mixture."}
              </p>
            </div>
          </div>
        </div>
        <div className="my-24">
          <div
            style={{
              backgroundImage: `url(${ValentiaPrePost})`,
              backgroundSize: "cover",
            }}
            className="h-96"
          >
            <div className=" grid grid-cols-2">
              <div className="h-96 text-xl font-semibold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-opacity-0 bg-opacity-50 bg-green-light flex items-center justify-center ">
                <p className="px-3 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-light rounded-full">
                  {props.lang === "de" ? "Mais" : "Corn"}
                </p>
                <br />
                <h3 className="mt-2 text-lg leading-8 font-extrabold tracking-tight text-white sm:text-lg">
                  {props.lang === "de"
                    ? "Kontrollfläche"
                    : "Control surface"}
                </h3>
              </div>
              <div className="h-96 text-xl font-semibold transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-opacity-0 bg-opacity-50 bg-green-light flex items-center justify-center ">
                <p className="px-3 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-light rounded-full">
                  {props.lang === "de" ? "Mais" : "Corn"}
                </p>
                <br />
                <h3 className="mt-2 text-lg leading-8 font-extrabold tracking-tight text-white sm:text-lg">
                  {props.lang === "de"
                    ? "Mit VALENTIA behandelt"
                    : "Treated with VALENTIA"}
                </h3>
              </div>
            </div>
          </div>
          <div className="z-10 bg-green-dark">
            {/* <img
            className="h-4/5 w-full"
            src={ValentiaPrePost}
            alt="Valentia Pre and Post"
          /> */}
          </div>
        </div>
        <div>
          <div lassName="mt-24">
            <div className="lg:grid lg:grid-cols-2 lg:gap-6 mt-12">
              <div className="prose prose-lg text-white lg:max-w-none">
                <h1 className="text-center mt-2 text-lg leading-8 font-bold tracking-tight text-white sm:text-xl">
                  Bekämpfung von Knötericharten
                </h1>
                <h3 className="text-center text-md leading-8 tracking-tight text-white sm:text-lg mb-8">
                  Wirkungsgrade aus n=74 Anwendungen
                </h3>
                <div className="overflow-x w-100">
                  <BarChart />
                </div>
              </div>
              <div className="sm:mt-12 lg:mt-32 prose prose-lg text-white lg:max-w-none">
                <p>
                  {props.lang === "de"
                    ? "VALENTIA zeichnet eine besondere Stärke auf Knötericharten aus. Im Ergebnis der Versuche aus 74 Anwendungen erzielten Anwendungen mit VALENTIA durchschnittlich 93% Wirkung.  Anwendungen ohne VALENTIA dagegen lediglich 83% Wirkung. "
                    : "VALENTIA is characterized by a particular strength on knotweed species. In the result of the tests from 74 applications, applications with VALENTIA achieved an average 93% effect.  In contrast, applications without VALENTIA achieved only 83% effect. "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Corn-Table">
        <div className="relative bg-green-dark">
          <div className="lg:absolute lg:inset-0">
            <div className="z-0 lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
              <img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                src={ValentiaCorn}
                alt="Valentia Corn"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="overflow-x-auto mx-6 ">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="relative pt-16 pb-16 px-2 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
                  <div className="lg:col-start-2 lg:pl-8">
                    <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
                      <div className="">
                        <table className="min-w-full">
                          <thead className="">
                            <tr>
                              <th
                                scope="col"
                                className="text-left text-xs font-bold text-white uppercase tracking-wider"
                              >
                                {props.lang === "de" ? "Befall" : "Infection"}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-center text-xs font-bold text-white uppercase tracking-wider"
                              >
                                Valentia 1,0 l/ha
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-center text-xs font-bold text-white uppercase tracking-wider"
                              >
                                Valentia 1,0 l/ha <br />+ Mesotrione
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {corntable.map((elm) => (
                              <tr
                                key={elm.nameDe}
                                className="border-t border-neutral-600"
                              >
                                <td className="whitespace-nowrap">
                                  <div className="flex items-center">
                                    <div className="">
                                      <div className="font-medium text-sm md:text-lg text-white">
                                        {props.lang === "de"
                                          ? elm.nameDe
                                          : elm.nameEn}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-8 py-2 whitespace-nowrap">
                                  <div className="grid place-items-center">
                                    <img
                                      className="w-5 h-5"
                                      src={elm.circle1}
                                      alt={elm.nameEn}
                                    />
                                  </div>
                                </td>
                                <td className="px-8 py-2 whitespace-nowrap">
                                  <div className="grid place-items-center">
                                    <img
                                      className="w-5 h-5"
                                      src={elm.circle2}
                                      alt={elm.nameEn}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="text-white text-xs inline-flex mr-4 mt-4">
                          <img
                            className="w-4 h-4 mx-1"
                            src={Greencircle1}
                            alt="top"
                          />{" "}
                          <p>
                            {props.lang === "de"
                              ? "sehr gut bis gut bekämpfbar"
                              : "very good to good control"}
                          </p>
                          <img
                            className="w-4 h-4 mx-1 ml-4"
                            src={Greencircle34}
                            alt="top"
                          />{" "}
                          <p>
                            {props.lang === "de"
                              ? "ausreichend bekämpfbar"
                              : "sufficiently controllable"}
                          </p>
                        </div>
                        <div className="text-white text-xs inline-flex my-4">
                          <img
                            className="w-4 h-4 mx-1"
                            src={Greencircle14}
                            alt="top"
                          />{" "}
                          <p>
                            {props.lang === "de"
                              ? "nicht ausreichend bekämpfbar"
                              : "not sufficiently controllable"}
                          </p>
                          <img
                            className="w-4 h-4 mx-1 ml-4"
                            src={Greencircle0}
                            alt="top"
                          />{" "}
                          <p>
                            {props.lang === "de"
                              ? "nicht bekämpfbar"
                              : "non-controllable"}
                          </p>
                        </div>
                        <div>
                          <p className="text-white text-xs my-4">
                            {props.lang === "de"
                              ? "*Blattmasse, wüchsige Bedingungen"
                              : "*Leaf mass, vigorous conditions"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-white ">
        <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
          <Divider text={"Empfehlung"} />
          <section id="RecommendationCorn" className="py-16">
                <h2 className="text-lg font-semibold tracking-tight text-green-medium sm:text-xl">
                  {props.lang === "de" ? "Empfehlung" : "Recommendation"}
                </h2>
                <h3 className="text-xl lg:pr-96 font-bold text-green-dark sm:text-3xl">
                  {props.lang === "de"
                    ? "VALENTIA sichere Unkrautbekämpfung in Tankmischungen von Anfang an "
                    : "VALENTIA safe weed control in tank mixes from the start "}
                </h3>
            <div className="relative mt-8 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
              <div className="relative">

                <dl className="space-y-10">
                  {transferFeatures.map((item) => (
                    <div key={item.id} className="relative">
                      <dt>
                        <div
                          className={classNames(
                            item.iconColor,
                            "absolute flex h-12 w-12 items-center justify-center rounded-xl text-white"
                          )}
                        >
                          <item.icon className="h-8 w-8" aria-hidden="true" />
                        </div>
                        <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                          {item.name}
                        </p>
                      </dt>
                      <dd className="mt-2 ml-16 text-base text-gray-500">
                        {props.lang === "de"
                          ? item.descriptionDe
                          : item.descriptionEn}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>

              <div className="pt-12 lg:mt-0" aria-hidden="true">
                <img
                  className="mx-auto"
                  width={600}
                  backgroundColor="#fff"
                  src={ValentiaCornInfo}
                  alt=""
                />
              </div>
            </div>
            <div className="relative mx-auto max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-6 mt-12">
            <div className="prose text-gray-600 lg:max-w-none lg:p-8">
              <p>
              {props.lang === "de"
                    ? "Aufgrund von Wirkstoffstreichungen nehmen schwer bekämpfbare Unkräuter wie Knöterich- und Windenarten deutlich zu. In Kombination mit Triketon- oder Sulfonylharnstoff- basierenden Herbiziden schließen reduzierte VALENTIA Aufwandmengen diese Lücken und mildern die Unkrautproblematik in nachfolgenden Kulturen."
                    : "Due to active ingredient deletions, difficult-to-control weeds such as knotweed and bindweed species are increasing significantly. In combination with triketone- or sulfonylurea-based herbicides, reduced VALENTIA application rates close these gaps and mitigate weed problems in subsequent crops."}
              </p>
            </div>
            <div className="prose prose-lg text-gray-600 lg:mt-0 lg:p-8">
              <p>
              {props.lang === "de"
                    ? "Unter diesen Bedingungen empfiehlt sich die Zugabe von VALENTIA mit reduzierten Aufwandmengen zur Absicherung von Herbizidstrategien."
                    : "Under these conditions, the addition of VALENTIA at reduced application rates is recommended to safeguard herbicide strategies."}
              </p>
            </div>
          </div>
        </div>
          </section>

          <section id="CompatibilityCorn">
            <Divider text={"Verträglichkeit"} />
            <div className="relative mt-12 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-24">
                <div className="lg:col-start-2">
                  <h2 className="text-lg font-semibold tracking-tight text-green-medium sm:text-xl">
                    {props.lang === "de" ? "Verträglichkeit" : "Compatibility"}
                  </h2>
                  <h3 className="text-xl font-bold text-green-dark sm:text-2xl">
                    {props.lang === "de"
                      ? "VALENTIA: Sanft zum Mais – hart zu Unkräutern"
                      : "VALENTIA: Gentle on corn - tough on weeds"}
                  </h3>
                  <p className="mt-3 text-md text-gray-500">
                    {props.lang === "de"
                      ? "Die Praxis hat es auf Zehntausenden von Hektar nach der Zulassung vor 3 Jahren gezeigt - VALENTIA ist voll verträglich."
                      : "Practice has shown it on tens of thousands of hectares after the approval 3 years ago - VALENTIA is fully tolerated. "}
                  </p>
                  <h4 className="mt-3 text-lg font-bold text-center text-green-medium">
                    {props.lang === "de"
                      ? "Fluroxypyr ist nicht Fluroxypyr!"
                      : "Fluroxypyr is not Fluroxypyr!"}
                  </h4>
                  <p className="mt-3 text-md text-gray-500">
                    {props.lang === "de"
                      ? "Die besondere Zusammensetzung der Grünen Formulierung in Verbindung mit angepassten Wirkstoffmengen machen den Unterschied."
                      : "The special composition of the GREEN FORMULATION in combination with adjusted active ingredient amounts make the difference."}
                  </p>
                  <div className="mt-8 rounded-md bg-green-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-green-800"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-semibold text-green-800">
                          {props.lang === "de"
                            ? "Hinweis zum kulturverträglichen Einsatz"
                            : "Note on culturally compatible use "}
                        </p>
                        <br />
                        <p className="text-sm text-green-800">
                          {props.lang === "de"
                            ? "Die Anwendung von VALENTIA wird wie bei allen Herbiziden  in folgenden Situationen nicht empfohlen: Behandlungen von gestressten Beständen. Behandlungen bei extremen Tag/Nacht Temperatur Unterschieden bzw. bei Frost oder Trockenheit.  Nach Regenperioden prüfen, ob sich eine Wachsschicht gebildet hat."
                            : "The use of VALENTIA, as with all herbicides, is not recommended in the following situations: Treatments of stressed stands. Treatments during extreme day/night temperature differences or during frost or drought.  After rainy periods, check if a wax layer has formed."}
                        </p>
                        <br />
                        <p className="text-sm text-green-800">
                          {props.lang === "de"
                            ? "Wichtig: Kombinationen mit Dicamba haltigen Herbiziden werden aktuell nicht empfohlen und weiter geprüft."
                            : "Important: Combinations with herbicides containing dicamba are not currently recommended and will be further evaluated."}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
                  <h1 className="text-center mt-2 text-lg leading-8 font-bold tracking-tight sm:text-xl">
                    % Phytotoxizität
                  </h1>
                  <h3 className="text-center text-md leading-8 tracking-tight sm:text-lg mb-8">
                    {props.lang === "de"
                      ? "über n=43 Sorten [LWK NRW 2022]"
                      : "over n=43 varieties [LWK NRW 2022]"}
                  </h3>
                  <BarChartPhyto />
                  <div className="mt-6">
                    <p className="text-sm text-grew-800">
                      {props.lang === "de"
                        ? "WD1 = Wuchsdeformation 6 Tage nach Anwendung."
                        : "WD1 = Growth deformation 6 days after application."}
                    </p>
                    <p className="text-sm text-grew-800">
                      {props.lang === "de"
                        ? "WD2 = Wuchsdeformation 21 Tage nach Anwendung."
                        : "WD2 = Growth deformation 21 days after application."}
                    </p>
                    <p className="text-sm text-grew-800">
                      {props.lang === "de"
                        ? "WH2 = Wuchshemmung 21 Tage nach Anwendung."
                        : "WH2 = Growth inhibition 21 days after application."}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <section id="resistence">
      <Divider text={"Resistenz"} />
        <div className="overflow-hidden bg-white mt-12">
          <div className="relative mx-auto max-w-7xl py-16 px-6 lg:px-8">
            <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
            <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
              <div>
                <h2 className="text-lg font-semibold text-green-light">
                  {props.lang === "de"
                    ? "Resistenzabwehr"
                    : "Resistance defense"}
                </h2>
                <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                  {props.lang === "de"
                    ? "VALENTIA hilft auch zukünftig Wirkung und Wirkstoffe zu sichern"
                    : "VALENTIA also helps to ensure impact and active ingredients in the future"}
                </h3>
              </div>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative lg:col-start-2 lg:row-start-1">
                <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                  <figure>
                    <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                      <img
                        className="rounded-lg object-cover object-center shadow-lg"
                        src={imgResistence}
                        alt="Whitney leaning against a railing on a downtown street"
                        width={1184}
                        height={1376}
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                <div className="mx-auto max-w-prose text-base lg:max-w-none">
                  <p className="text-lg text-gray-500">
                    {props.lang === "de"
                      ? "Durch den Wegfall und die Einschränkungen bei vielen Wirkstoffen kommt es zu einer Dominanz von Sulfonylharnstoff basierten Maisherbiziden und Wirkmechanismen – die Resistenzgefahr steigt. "
                      : "The elimination and restrictions of many active ingredients leads to a dominance of sulfonylurea-based corn herbicides and mechanisms of action - the risk of resistance increases. "}
                  </p>
                </div>
                <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
                  <p>
                    {props.lang === "de"
                      ? "Der Auxin Wirkmechanismus (WSSA 4) in VALENTIA führt zu einem Wirkstoffwechsel und damit einer Resistenzabsicherung bei vielen Unkräutern. Denn Auxine haben ein sehr geringes Resistenzrisiko."
                      : "The auxin mode of action (WSSA 4) in VALENTIA leads to a change in active ingredient and thus resistance protection in many weeds. This is because auxins have a very low resistance risk."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="bromoxynil-alternativ" className="mt-36">
        <Divider text={"bromoxynil alternativ"} />

        <div className="bg-green-dark mt-8">
          <div className="mx-auto max-w-7xl">
            <div>
              <div className="sm:text-center pt-8 pl-8">
                <h2 className="text-lg font-semibold leading-8 text-green-lighter">
                  {props.lang === "de"
                    ? "„Bromoxynil Ersatz“"
                    : "“Bromoxynil substitute“"}
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl lg:px-48">
                  {props.lang === "de"
                    ? "Gezielt, schnell und sicher im Vergleich mit Prosulfuron und Dicamba"
                    : "Targeted, fast and safe compared with prosulfuron and dicamba"}
                </p>
              </div>
              <div className="pb-8">
                <img
                  className="h-full w-full lg:px-48 object-cover"
                  src={BromxynilAlternativ}
                  alt="GRÜNE FORMULIERUNG"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="ValentiaCereal">
        <div className="bg-white lg:px-16">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
            <div>
              <a
                href="/"
                className="inline-flex items-center text-white bg-gray-100 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
              >
                <span className="px-3 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-yellow-light rounded-full">
                  {props.lang === "de" ? "Getreide" : "Cereal"}
                </span>
              </a>

              <h2 className="mt-2 text-base font-semibold text-yellow-light uppercase tracking-wide">
                Valentia
              </h2>
              <p className="mt-2 text-2xl font-extrabold text-gray-900">
                {props.lang === "de" ? "Top in Getreide" : "Top in cereals"}
              </p>
              <div className="grid place-items-center mt-6">
                <img alt="Corn" src={ValentiaCereals} className="w-48" />
              </div>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-10 sm:space-y-3 items-center sm:grid sm:grid-cols-2 sm:grid-rows-3 ">
                {highlightsCereals.map((content) => (
                  <div key={content.descriptionEn} className="relative">
                    <dt>
                      <CheckIcon
                        className="absolute h-6 w-6 text-yellow-light"
                        aria-hidden="true"
                      />
                      <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                        {props.lang === "de"
                          ? content.descriptionDe
                          : content.descriptionEn}
                      </p>
                    </dt>
                    {/* <dd className="mt-2 ml-9 text-base text-gray-500">
                    {props.lang==='de' ? content.descriptionEn : content.descriptionDe}
                    </dd> */}
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </section>

      <section id="Cereal-Text">
        <div className="relative bg-yellow-medium p-16">
          <div>
            <h2 className="text-base text-white font-semibold tracking-wide uppercase">
              Valentia
            </h2>
            <h3 className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-green-dark sm:text-3xl">
              {props.lang === "de"
                ? "Sicher gegen Klette & Co. in W-Gerste und W-Weizen"
                : "Safe against burdock & co. in W-barley and W-wheat"}

              <br />
              {props.lang === "de"
                ? "und flexible Unkrautbekämpfung"
                : "and flexible weed control"}
            </h3>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-6 mt-6">
            <div className="prose prose-indigo prose-lg text-white lg:max-w-none">
              <p>
                {props.lang === "de"
                  ? "Die bewährte Wirkstoffkombination aus Fluroxypyr und Florasulam ist der Garant für eine preiswerte, unkomplizierte und flexible Unkrautbekämpfung in W-Weizen und W-Gerste. Im Solo-Einsatz werden bereits die wichtigsten Unkräuter bekämpft. "
                  : "The proven active ingredient combination of fluroxypyr and florasulam is the guarantor for inexpensive, uncomplicated and flexible weed control in W wheat and W barley. In solo use, the most important weeds are already controlled."}
              </p>
            </div>
            <div className="mt-6 prose prose-indigo prose-lg text-white lg:mt-0">
              <p>
                {props.lang === "de"
                  ? "Bei starkem Auftreten von Stiefmütterchen bieten sich Tankmischungen mit Tribenuron beziehungsweise bei Distel mit MCPA an. Unterschiedliche Wirkmechanismen (WSSA/HRAC Resistenzgruppe 4 (O) und 2 (B)) beugen Resistenzen vor."
                  : "In the case of heavy occurrence of pansies, tank mixtures with tribenuron or, in the case of thistle, with MCPA are recommended. Different modes of action (WSSA/HRAC resistance group 4 (O) and 2 (B)) prevent resistance."}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="Creals-Infographik">
        <div className="relative my-12">
          <div className="mt-3">
            <div className="prose prose-indigo prose-lg lg:max-w-none">
              <div className="">
                <img alt="Ceareals" src={ValentiaCerealInfo} className="lg:mx-24" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Cereal-Table">
        <div className="relative bg-brown-darker">
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/3">
              <img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                src={ValentiaCerealsImg}
                alt="Valentia Corn"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="overflow-x-auto mx-6 ">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="relative pt-16 pb-16 px-2 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-3">
                  <div className="lg:col-start-2 lg:pl-8">
                    <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
                      <div className="...">
                        <table className="min-w-full">
                          <thead className="">
                            <tr>
                              <th
                                scope="col"
                                className="text-left text-xs font-bold text-white uppercase tracking-wider"
                              >
                                {props.lang === "de" ? "Befall" : "Infestation"}
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-center text-xs font-bold text-white uppercase tracking-wider"
                              >
                                Valentia 1,5 l/ha
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-center text-xs font-bold text-white uppercase tracking-wider"
                              >
                                Valentia 1,0 l/ha <br />+ MCPA 1,0 l/ha
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-center text-xs font-bold text-white uppercase tracking-wider"
                              >
                                Valentia 1,0 l/ha <br />+ Tribenuron 30 g/ha
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {cerealstable.map((elm) => (
                              <tr
                                key={elm.nameDe}
                                className="border-t border-neutral-600"
                              >
                                <td className="py-2 whitespace-nowrap ">
                                  <div className="flex items-center">
                                    <div className="">
                                      <div className="font-medium text-sm md:text-lg text-white">
                                        {props.lang === "de"
                                          ? elm.nameDe
                                          : elm.nameEn}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap">
                                  <div className="grid place-items-center">
                                    <img
                                      className="w-5 h-5"
                                      src={elm.circle1}
                                      alt={elm.nameEn}
                                    />
                                  </div>
                                </td>
                                <td className="py-2 whitespace-nowrap">
                                  <div className="grid place-items-center">
                                    <img
                                      className="w-5 h-5"
                                      src={elm.circle2}
                                      alt={elm.nameEn}
                                    />
                                  </div>
                                </td>
                                <td className="py-2 whitespace-nowrap">
                                  <div className="grid place-items-center">
                                    <img
                                      className="w-5 h-5"
                                      src={elm.circle3}
                                      alt={elm.nameEn}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="">
                        <div className="text-white text-xs inline-flex mr-4 mt-4">
                          <img
                            className="w-4 h-4 mx-1"
                            src={Yellowcircle1}
                            alt="top"
                          />{" "}
                          <p>
                            {props.lang === "de"
                              ? "sehr gut bis gut bekämpfbar"
                              : "very good to good control"}
                          </p>
                          <img
                            className="w-4 h-4 mx-1 ml-4"
                            src={YellowCircle34}
                            alt="top"
                          />{" "}
                          <p>
                            {props.lang === "de"
                              ? "ausreichend bekämpfbar"
                              : "sufficiently controllable"}
                          </p>
                        </div>
                        <div className="text-white text-xs inline-flex my-4">
                          <img
                            className="w-4 h-4 mx-1"
                            src={Yellowcircle14}
                            alt="top"
                          />{" "}
                          <p>
                            {props.lang === "de"
                              ? "nicht ausreichend bekämpfbar"
                              : "not sufficiently controllable"}
                          </p>
                          <img
                            className="w-4 h-4 mx-1 ml-4"
                            src={Greencircle0}
                            alt="top"
                          />{" "}
                          <p>
                            {props.lang === "de"
                              ? "nicht bekämpfbar"
                              : "non-controllable"}
                          </p>
                        </div>
                        <div>
                          <p className="text-white text-xs my-4">
                            {props.lang === "de"
                              ? "*Blattmasse, wüchsige Bedingungen"
                              : "*Leaf mass, vigorous conditions"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="ContactValentia">
      <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-6 text-center lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-2xl">
            <h2 className="text-xl font-bold tracking-tight sm:text-3xl">
            {props.lang === "de"
                              ? "Kontaktieren Sie uns gerne persönlich"
                              : "Meet our team"}
            </h2>
            <p className="text-xl text-gray-500">
            {props.lang === "de"
                              ? "Wir beraten Sie gerne bezüglich Einsatz und Bezugsquellen"
                              : "We would be happy to advise you on use and sources of supply"}
            </p>
          </div>
          <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:max-w-5xl lg:grid-cols-4">
            {people.map((person) => (
              <li key={person.name}>
                <div className="space-y-6">
                  <img className="mx-auto h-32 w-32 rounded-full xl:h-40 xl:w-40 object-cover" src={person.imageUrl} alt="" />
                  <div className="space-y-2">
                    <div className="space-y-1 text-md font-medium leading-6">
                      <h3 className="font-semibold">{person.name}</h3>
                      <p className="text-green-light">
                      {props.lang === "de"
                              ? person.roleDe
                              : person.roleEn}
                      </p>
                    </div>
                    <ul className="flex justify-center space-x-5">
                      <li>
                        <a href={person.Mail} className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Mail</span>
                          <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M3 4C1.89543 4 1 4.89543 1 6V7.16147L9.44098 11.382C9.79289 11.5579 10.2071 11.5579 10.559 11.382L19 7.16147V6C19 4.89543 18.1046 4 17 4H3Z"/>
                            <path d="M19 8.83853L11.2298 12.7236C10.4556 13.1107 9.54436 13.1107 8.77016 12.7236L1 8.83853V14C1 15.1046 1.89543 16 3 16H17C18.1046 16 19 15.1046 19 14V8.83853Z"/>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">LinkedIn</span>
                          <svg className="h-8 w-8" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
      </section>
    </>
  );
}
