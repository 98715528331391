import "./styles.css";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "WD1",
    "VGM 1 (Standard-Fluroxypyr Solo Formulierung) 0,9 l/ha": 2.2,
    "VGM 2 (Iodosulf./Foramsulf./Thiencarb.) 1,5 l/ha": 0.3,
    "Valentia 1,8 l/ha": 1.8,
  },
  {
    name: "WD2",
    "VGM 1 (Standard-Fluroxypyr Solo Formulierung) 0,9 l/ha": 2.6,
    "VGM 2 (Iodosulf./Foramsulf./Thiencarb.) 1,5 l/ha": 1.3,
    "Valentia 1,8 l/ha": 1.1,
  },
  {
    name: "WH2",
    "VGM 1 (Standard-Fluroxypyr Solo Formulierung) 0,9 l/ha": 2.8,
    "VGM 2 (Iodosulf./Foramsulf./Thiencarb.) 1,5 l/ha": 1.3,
    "Valentia 1,8 l/ha": 1.1,
  },
];

export default function BarChartPhyto() {
  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <BarChart width={500} height={400} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="VGM 1 (Standard-Fluroxypyr Solo Formulierung) 0,9 l/ha" fill="#999" />
          <Bar dataKey="VGM 2 (Iodosulf./Foramsulf./Thiencarb.) 1,5 l/ha" fill="#555" />
          <Bar dataKey="Valentia 1,8 l/ha" fill="#479900" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
