import React from "react";

export default function Divider(props) {
    return (
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-white" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-sm text-white">{props.text}</span>
        </div>
      </div>
    )
  }