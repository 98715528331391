import React from "react";

import berndBund from "../image/jpg/formgroup-berndBund.jpg";
import berndBelitz from "../image/jpg/formgroup-berndbelitz.JPG";
import hugoSchweers from "../image/jpg/formgroup-hugoschweers.jpg";
import bernhardOverberg from "../image/jpg/formgroup-bernhardoverberg.jpg";

const people = [
    {
      name: 'Hugo Schweers',
      roleDe: "35+ Jahre Erfahrung im Agrobusiness + ex. Vorstandsmitglied Agravis/Nufarm",
      roleEn: '35+ years of experience in agribusiness + ex. Boardmember Agravis/Nufarm',
      imageUrl: hugoSchweers,
      Mail: 'mailto:hugo.schweers@formgroup.io',
      linkedinUrl: 'https://www.linkedin.com/in/hugo-schweers-bb449588/',
    },
    {
      name: 'Bernd Belitz',
      roleDe: "30+ Jahre Erfahrung im Agrobusiness",
      roleEn: '30+ years of experience in agribusiness',
      imageUrl: berndBelitz,
      Mail: 'mailto:bernd.belitz@formgroup.io',
      linkedinUrl: 'https://www.linkedin.com/in/bernd-belitz-768b9b72/',
    },
    {
      name: 'Dr. Hans-Bernhard Overberg',
      roleDe: "20+ Jahre Erfahrung im Agrobusiness",
      roleEn: '20+ years of experience in agribusiness',
      imageUrl: bernhardOverberg,
      Mail: 'mailto:hansbernhard.overberg@formgroup.io',
      linkedinUrl: 'https://www.linkedin.com/in/dr-hans-bernhard-overberg/',
    },
    {
      name: 'Bernd K. Bund',
      roleDe: "25+ Jahre Erfahrung im Agrobusiness und selbstständiger Landwirt",
      roleEn: '25+ years of experience in agribusiness and self-employed farmer',
      imageUrl: berndBund,
      Mail: 'mailto:bernd.bund@formgroup.io',
      linkedinUrl: '',
    },
    // More people...
  ]

export default function People(props) {
  return (
    <>
      <section id="ContactValentia">
        <div className="bg-white mb-24">
          <div className="mx-auto max-w-7xl py-12 px-6 text-center lg:px-8 lg:py-24">
            <div className="space-y-12">
              <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-2xl">
                <h2 className="text-xl font-bold tracking-tight sm:text-3xl">
                  {props.lang === "de"
                    ? "Kontaktieren Sie uns gerne persönlich"
                    : "Meet our team"}
                </h2>
                <p className="text-xl text-gray-500">
                  {props.lang === "de"
                    ? "Wir beraten Sie gerne bezüglich Einsatz und Bezugsquellen"
                    : "We would be happy to advise you on use and sources of supply"}
                </p>
              </div>
              <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:max-w-5xl lg:grid-cols-4">
                {people.map((person) => (
                  <li key={person.name}>
                    <div className="space-y-6">
                      <img
                        className="mx-auto h-32 w-32 rounded-full xl:h-40 xl:w-40 object-cover"
                        src={person.imageUrl}
                        alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-md font-medium leading-6">
                          <h3 className="font-semibold">{person.name}</h3>
                          <p className="text-green-light">
                            {props.lang === "de"
                              ? person.roleDe
                              : person.roleEn}
                          </p>
                        </div>
                        <ul className="flex justify-center space-x-5">
                          <li>
                            <a
                              href={person.Mail}
                              className="text-gray-400 hover:text-gray-500"
                            >
                              <span className="sr-only">Mail</span>
                              <svg
                                className="h-8 w-8"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M3 4C1.89543 4 1 4.89543 1 6V7.16147L9.44098 11.382C9.79289 11.5579 10.2071 11.5579 10.559 11.382L19 7.16147V6C19 4.89543 18.1046 4 17 4H3Z"
                                />
                                <path d="M19 8.83853L11.2298 12.7236C10.4556 13.1107 9.54436 13.1107 8.77016 12.7236L1 8.83853V14C1 15.1046 1.89543 16 3 16H17C18.1046 16 19 15.1046 19 14V8.83853Z" />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a
                              href={person.linkedinUrl}
                              className="text-gray-400 hover:text-gray-500"
                            >
                              <span className="sr-only">LinkedIn</span>
                              <svg
                                className="h-8 w-8"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
