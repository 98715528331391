import "./styles.css";
import React from "react";
import {
  BarChart,
  Cell,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const colors = ["#ffffff", "#59BF00"];

const data = [
  {
    name: "Ohne Valentia",
    "Wirkungsgrad [%]": 83,
    amt: 100,
  },
  {
    name: "Mit Valentia",
    "Wirkungsgrad [%]": 95,
    amt: 100,
  },
];

export default function App() {
  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <BarChart

          width={350}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          barSize={100}
        >
          <XAxis
            dataKey="name"
            scale="point"
            stroke="#fff"
            padding={{ left: 100, right: 100 }}
          />
          <YAxis y={50} stroke="#fff" domain={[50, 100]}/>
          <Tooltip labelStyle={{ color: "#000" }} />
          <Legend />
          <Bar
            dataKey="Wirkungsgrad [%]"
            fill="#162718"
            label={{
              position: "center",
              fill: "#162718",
              fontSize: 20,
              fontWeight: 600,
            }}
            background={{ fill: "#ffffff50" }}
            animationBegin={1000}
          >
            {data.map((entry, index) => (
              <>
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              </>
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
