import React from "react";

import { DocumentDownloadIcon } from "@heroicons/react/solid";

import pdf1 from './../image/pdf/reports/Marktreport_KW7.pdf'
import pdf2 from './../image/pdf/reports/Marktreport_KW8.pdf'
import pdf3 from './../image/pdf/reports/Marktreport_KW9.pdf'
import pdf4 from './../image/pdf/reports/Marktreport_KW10.pdf'
import pdf5 from './../image/pdf/reports/Marktreport_KW11.pdf'
import pdf6 from './../image/pdf/reports/Marktreport_KW12.pdf'
import pdf7 from './../image/pdf/reports/Marktreport_KW13.pdf'
import pdf8 from './../image/pdf/reports/Marktreport_KW14.pdf'
import pdf9 from './../image/pdf/reports/Marktreport_KW15.pdf'
import pdf10 from './../image/pdf/reports/Marktreport_KW16.pdf'
import pdf11 from './../image/pdf/reports/Marktreport_KW17.pdf'
import pdf12 from './../image/pdf/reports/Marktreport_KW18.pdf'
import pdf14 from './../image/pdf/reports/Marktreport_KW20.pdf'

export const news = [
    {
      id: 2021-1,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf1,
      date: "KW07",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },
    {
      id: 2021-2,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf2,
      date: "KW08",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },
    {
      id: 2021-3,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf3,
      date: "KW09",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },
    {
      id: 2021-4,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf4,
      date: "KW10",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },
    {
      id: 2021-5,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf5,
      date: "KW11",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },
    {
      id: 2021-6,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf6,
      date: "KW12",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },
    {
      id: 2021-7,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf7,
      date: "KW13",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },
    {
      id: 2021-8,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf8,
      date: "KW14",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },
    {
      id: 2021-9,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf9,
      date: "KW15",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },
    {
      id: 2021-10,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf10,
      date: "KW16",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },
    {
      id: 2021-11,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf11,
      date: "KW17",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },
    {
      id: 2021-12,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf12,
      date: "KW18",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },
    {
      id: 2021-14,
      content: "Marktreport",
      target: <nobr>Hot News</nobr>,
      href: pdf14,
      date: "KW20",
      icon: DocumentDownloadIcon,
      iconBackground: "bg-green-lighter",
    },

    
  ];