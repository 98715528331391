import React, { Component } from "react";

import AgroformIntro from "../image/mp4/agroform_Intro.mp4";
import AgroNewsImg from "../image/jpg/agroNews_image.jpg";
import AgroWorkshop from "../image/jpg/agroWorkshops_image.jpg";
import AgroformIcon from "../image/svg/agroform_icon.svg";
// import agroformLogoWhite from "../image/svg/agroform_logo_white.svg";

import berndBund from "../image/jpg/formgroup-berndBund.jpg";
import berndBelitz from "../image/jpg/formgroup-berndbelitz.JPG";
import hugoSchweers from "../image/jpg/formgroup-hugoschweers.jpg";
import bernhardOverberg from "../image/jpg/formgroup-bernhardoverberg.jpg";

import { productData } from "./products";

import ProductList from "./productList";

import { news } from "./news";

import IconCereal from "../image/svg/ICON_fg_cereal.svg";
import IconCorn from "../image/svg/ICON_fg_corn.svg";
import IconGrassland from "../image/svg/ICON_fg_grassland.svg";
import IconSoil from "../image/svg/ICON_fg_soil.svg";
import IconRape from "../image/svg/ICON_fg_rape.svg";

import { MailIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default class Index extends Component {
  state = {
    products: productData,
    filterState: productData,
  };

  handleFilter = (elm) => {
    let filterElm;

    if (elm.target.alt === "") {
      filterElm = this.state.products;
    } else {
      filterElm = this.state.products.filter(
        (item) =>
          item.field1 === elm.target.alt || item.field2 === elm.target.alt
      );
    }

    this.setState({
      filterState: filterElm,
    });
  };

  render(props) {
    return (
      <>
        <main className="bg-brown-darker">
          <div>
            {/* Hero card */}
            <div className="relative">
              <div className="absolute inset-x-0 bottom-0" />
              <div className=" mx-auto">
                <div className="relative sm:overflow-hidden">
                  <div className="mx-auto absolute">
                    
                    <video
                      loop={true} autoPlay={true} playsInline={true}
                      className="h-screen object-cover bg-brown-darker"
                    >
                      <source src={AgroformIntro} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <div className="absolute inset-0 mix-blend-overlay" />
                  </div>
                  <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                    <div className="lg:grid lg:grid-cols-12 lg:gap-8 ">
                      <div className="bg-green-dark bg-opacity-50 p-5 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                        <div>
                          <a
                            href="mailto:bernd.bund@formgroup.io?bcc=info@formgroup.io&subject=Website%20Anfrage:%20"
                            className="inline-flex items-center text-white bg-green-dark rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                          >
                            <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-light rounded-full">
                              formgroup
                            </span>
                            <span className="ml-4 text-sm">
                              {this.props.lang === "de"
                                ? "Plattform"
                                : "Platform"}
                            </span>
                          </a>
                          <h1 className="mt-4 text-2xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-2xl xl:text-3xl">
                            <span className="md:block">
                              {this.props.lang === "de"
                                ? "agroform - Unsere Produkte, Know-how und Expertise"
                                : "agroform - Our products, know-how and expertise"}
                            </span>{" "}
                            <span className="text-green-lighter md:block">
                              {this.props.lang === "de"
                                ? "im Bereich Landwirtschaft auf einer Plattform"
                                : "in the field of agriculture on one platform"}
                            </span>
                          </h1>
                          <p className="mt-3 text-base font-medium text-white sm:mt-5">
                            {this.props.lang === "de"
                              ? "Wir wollen nichts weniger, als Qualitätsprodukte direkt vom Produzenten durch enge Zusammenarbeit mit dem Großhandel zum vollen Potential zu bringen."
                              : "We want nothing less than bringing quality products directly from the producer to their full potential through close cooperation with wholesalers."}
                          </p>

                          <div>
                            <p className="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-10">
                              formgroup Agent
                            </p>
                            <div className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                              <div className="flex flex-wrap items-start justify-between">
                                <div className="flex items-center space-x-4 lg:space-x-6">
                                  <img
                                    className="w-16 h-16 rounded-full"
                                    src={berndBund}
                                    alt="Bernd Bund - formgroup expert"
                                  />
                                  <div className="text-md leading-6 space-y-1 text-white">
                                    <h3 className="font-semibold ">
                                      Bernd Bund
                                    </h3>
                                    <p className="text-sm font-regular text-white">
                                      {this.props.lang === "de"
                                        ? "25+ Jahre Erfahrung im Agrobusiness und selbstständiger Landwirt"
                                        : "25+ years of experience in agribusiness and self-employed farmer"}
                                    </p>
                                    <a
                                      href="mailto:bernd.bund@formgroup.io?bcc=info@formgroup.io&subject=Website%20Anfrage:%20"
                                      className="flex items-center justify-center w-24 px-1 py-1 text-base font-medium rounded-md shadow-sm text-white bg-green-light hover:bg-green-medium"
                                    >
                                      Kontakt
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-8 lg:mt-0 lg:col-span-6">
                        <div className="sm:overflow-hidden">
                          <div className="px-4 py-8">
                            <div className="flex justify-center">
                              <img
                                src={AgroformIcon}
                                className="w-72 lg:w-auto"
                                alt="agroform"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Logo cloud */}
            <section id="productfinder" className="">
              <div className="">
                <div className="relative mt-12 p-5">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-brown-light" />
                  </div>
                  <div className="relative flex justify-start">
                    <span className="p-1 bg-brown-darker text-md font-medium uppercase text-brown-light">
                      {this.props.lang === "de"
                        ? "ProduktFinder"
                        : "Product Finder"}
                    </span>
                  </div>
                </div>

                <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
                  <p className="text-center text-md font-semibold uppercase text-brown-light tracking-wide">
                    {this.props.lang === "de"
                      ? "Die Produkte unserer Partner sind vielseitig anwendbar speziell in den Bereichen:"
                      : "The products of our partners are versatilely applicable especially in the areas of:"}
                  </p>
                  <div className="my-12 grid grid-cols-5 gap-24 overflow-x-auto">
                    <div className="w-24 col-span-1 flex justify-center gap-1">
                      <button
                        onClick={this.handleFilter}
                        className="opacity-50 hover:opacity-100 focus:opacity-100"
                      >
                        <h1 className="text-center item-center text-sm md:text-md font-medium uppercase tracking-tight">
                          <img
                            className="m-4 h-24 w-18 md:w-24 mx-auto"
                            src={IconCereal}
                            alt="Cereals"
                          />
                          <span className="block text-white">
                            {this.props.lang === "de" ? "Getreide" : "Cereals"}
                          </span>
                        </h1>
                      </button>
                    </div>

                    <div className="w-24 col-span-1 flex justify-center">
                      <button
                        onClick={this.handleFilter}
                        className="opacity-50 hover:opacity-100 focus:opacity-100"
                      >
                        <h1 className="text-center item-center text-sm md:text-md font-medium uppercase tracking-tight">
                          <img
                            className="m-4 h-24 w-18 md:w-24 mx-auto"
                            src={IconCorn}
                            alt="Corn"
                          />
                          <span className="block text-white">
                            {this.props.lang === "de" ? "Mais" : "Corn"}
                          </span>
                        </h1>
                      </button>
                    </div>

                    <div className="w-24 col-span-1 flex justify-center">
                      <button
                        onClick={this.handleFilter}
                        className="opacity-50 hover:opacity-100 focus:opacity-100"
                      >
                        <h1 className="text-center item-center text-sm md:text-md font-medium uppercase tracking-tight">
                          <img
                            className="m-4 h-24 w-18 md:w-24 mx-auto"
                            src={IconRape}
                            alt="Rape"
                          />
                          <span className="block text-white">
                            {this.props.lang === "de" ? "Raps" : "Rapeseed"}
                          </span>
                        </h1>
                      </button>
                    </div>

                    <div className="w-24 col-span-1 flex justify-center">
                      <button
                        onClick={this.handleFilter}
                        className="opacity-50 hover:opacity-100 focus:opacity-100"
                      >
                        <h1 className="text-center item-center text-sm md:text-md font-medium uppercase tracking-tight align-top">
                          <img
                            className="my-4 h-24 w-18 md:w-24 mx-auto"
                            src={IconSoil}
                            alt="Potato"
                          />
                          <span className="block text-white">
                            {this.props.lang === "de"
                              ? "Kartoffeln"
                              : "Potatos"}
                          </span>
                        </h1>
                      </button>
                    </div>

                    <div className="w-24 col-span-1 flex justify-center">
                      <button
                        onClick={this.handleFilter}
                        className="opacity-50 hover:opacity-100 focus:opacity-100"
                      >
                        <h1 className="text-center item-center text-sm md:text-md font-medium uppercase tracking-tight">
                          <img
                            className="my-4 h-24 w-18 md:w-24 mx-auto"
                            src={IconGrassland}
                            alt="Grassland"
                          />
                          <span className="block text-white ">
                            {this.props.lang === "de"
                              ? "Grünland"
                              : "Grassland"}
                          </span>
                        </h1>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="overflow-x-auto">
                  <ProductList
                    products={this.state.filterState}
                    lang={this.props.lang}
                  />
                </div>
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative px-8 py-8 lg:py-12 lg:px-12 lg:flex lg:flex-row">
                  <div className="lg:mr-24 lg:w-96">
                    <div className="flex-col lg:mt-8">
                      <h1 className="font-semibold text-xl text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-2xl xl:text-3xl">
                        {this.props.lang === "de"
                          ? "Haben Sie Fragen zu unsem Produktportfolio?"
                          : "Do you have any questions about our product portfolio?s"}
                      </h1>
                      <h2 className="font-medium text-lg text-brown-light sm:mt-5 sm:leading-none lg:mt-6 lg:text-xl xl:text-2xl">
                        {this.props.lang === "de"
                          ? "Wenden Sie sich an einen unserer Experten."
                          : "Contact one of our experts."}
                      </h2>
                    </div>
                  </div>

                  <div className="">
                    <div className="flex-col">
                      <p className="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-10">
                        formgroup Agent
                      </p>
                      <div className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                        <div className="flex flex-wrap items-start justify-between">
                          <div className="flex items-center space-x-4 lg:space-x-6">
                            <img
                              className="w-16 h-16 rounded-full"
                              src={berndBelitz}
                              alt="Bernd Belitz - formgroup expert"
                            />
                            <div className="leading-6 space-y-1 text-white">
                              <h3 className="font-semibold text-lg">Bernd Belitz</h3>
                              <p className="text-sm font-regular text-white">
                                {this.props.lang === "de"
                                  ? "30+ Jahre Erfahrung im Agrobusiness"
                                  : "30+ years of experience in agribusiness"}
                              </p>
                              <a
                                href="mailto:bernd.belitz@formgroup.io?bcc=info@formgroup.io&subject=Website%20Anfrage:%20"
                                className="flex items-center justify-center w-24 px-2 py-1 text-base font-medium rounded-md shadow-sm text-white bg-green-light hover:bg-green-medium"
                              >
                                {this.props.lang === "de"
                                  ? "Kontakt"
                                  : "Contacts"}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="Reports" className="">
              <div className="relative mt-24 p-5">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-brown-light" />
                </div>
                <div className="relative flex justify-start">
                  <span className="p-1 bg-brown-darker text-md font-medium uppercase text-brown-light">
                    {this.props.lang === "de" ? "DE" : "EN"}Marktreports
                  </span>
                </div>
              </div>

              <div className="">
                <div className="mx-auto px-4 sm:py-12 sm:px-6 lg:px-48">
                  <div className="text-center">
                    <h2 className="text-base font-semibold text-brown-light tracking-wide uppercase">
                      {this.props.lang === "de"
                        ? "Agro Marktreport"
                        : "Agro Market Report"}
                    </h2>
                    <p className="mt-1 text-3xl font-extrabold text-white sm:tracking-tight lg:text-5xl">
                      {this.props.lang === "de"
                        ? "Marktreport unsere Experten zum freien Download"
                        : "Download our market reports for free download"}
                    </p>
                    <p className="max-w-xl mt-5 mx-auto text-xl text-brown-light">
                      {this.props.lang === "de"
                        ? "Unsere Analyse und Einschätzung der aktuellen Marktsituation im Agrarbereich finden Sie hier im regelmäßigen Abstand zum freien Download. Nutzen Sie die weltweiten Informationen für Planungsansätze und Tendenzentscheidungen."
                        : "Our analysis and assessment of the current market situation in the agricultural sector can be downloaded here for free download at regular intervals. Use the information for planning approaches and decisions on trends."}
                    </p>

                    <div className="max-w-md text-left mx-auto">
                      <p className="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-10">
                        formgroup Agent
                      </p>
                      <div className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                        <div className="flex flex-wrap items-start justify-between">
                          <div className="flex items-center space-x-4 lg:space-x-6">
                            <img
                              className="w-16 h-16 object-cover rounded-full"
                              src={bernhardOverberg}
                              alt="Bernd Belitz - formgroup expert"
                            />
                            <div className="text-md leading-6 space-y-1 text-white">
                              <h3 className="font-semibold ">
                                Hans-Bernhard Overberg
                              </h3>
                              <p className="text-sm font-regular text-white">
                                {this.props.lang === "de"
                                  ? "20+ Jahre Erfahrung im Agrobusiness"
                                  : "20+ years of experience in agribusiness"}
                              </p>
                              <a
                                href="mailto:hansbernhard.overberg@formgroup.io?bcc=info@formgroup.io&subject=Website%20Anfrage:%20"
                                className="flex items-center justify-center w-24 px-2 py-1 text-base font-medium rounded-md shadow-sm text-white bg-green-light hover:bg-green-medium"
                              >
                                {this.props.lang === "de"
                                  ? "Kontakt"
                                  : "Contact"}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg: relative mt-3 bg-brown-dark">
                <div className="mx-auto max-w-7xl w-full pt-6 pb-20 text-center lg:py-48 lg:text-left">
                  <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                    <div className="flow-root">
                      <ul className="mb-8">
                        {news.map((event, eventIdx) => (
                          <li key={event.id}>
                            <div className="relative pb-12">
                              {eventIdx !== news.length - 1 ? (
                                <span
                                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-brown-light"
                                  aria-hidden="true"
                                />
                              ) : null}
                              <div className="relative flex space-x-3">
                                <div>
                                  <span
                                    className={classNames(
                                      event.iconBackground,
                                      "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-brown-light"
                                    )}
                                  >
                                    <event.icon
                                      className="h-5 w-5 text-white"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </div>
                                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                  <div>
                                    <p className="text-md text-white hover:underline">
                                      <a
                                        href={event.href}
                                        id={event.id}
                                        download
                                      >
                                        {event.content}{" "}
                                      </a>
                                      <a
                                        href={event.href}
                                        id={event.id}
                                        download
                                        className="px-3 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-light rounded-full"
                                      >
                                        {event.target}
                                      </a>
                                    </p>
                                  </div>
                                  <div className="text-right font-semibold text-sm whitespace-nowrap text-white">
                                    <time dateTime={event.datetime}>
                                      {event.date}
                                    </time>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
                  <img
                    className="absolute inset-0 w-full h-full object-cover"
                    src={AgroNewsImg}
                    alt="agronews"
                  />
                </div>
              </div>
            </section>

            <section id="Workshops" className="">
              <div className="relative mt-24 p-5">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-brown-light" />
                </div>
                <div className="relative flex justify-start">
                  <span className="p-1 bg-brown-darker text-md font-medium uppercase text-brown-light">
                    Workshops
                  </span>
                </div>
              </div>
              <div className="relative bg-brown-dark">
                <div className="h-56 bg-green-light sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                  <img
                    className="w-full h-full object-cover"
                    src={AgroWorkshop}
                    alt=""
                  />
                </div>
                <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                  <div className="md:ml-auto md:w-1/2 md:pl-10">
                    <h2 className="text-base font-semibold uppercase tracking-wider text-brown-light">
                      {this.props.lang === "de"
                        ? "Meet the agroform-experts"
                        : "Meet the agroform-experts"}
                    </h2>
                    <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
                      {this.props.lang === "de"
                        ? "Sie bevorzugen den persönlichen Austausch? In einem Workshop tauschen wir uns gerne mit Ihnen aus."
                        : "Do you prefer personal exchange? We would be happy to exchange ideas with you in a workshop."}
                    </p>
                    <p className="mt-3 text-lg text-white">
                      {this.props.lang === "de"
                        ? "Aufarbeitung von Themen – Produkteinschätzungen, Planungshilfen, Organisationsentwicklung, Führungs- und Motivationsthemen – werden auf Kunden- und Lieferantenwunsch von unseren Experten erarbeitet und in online-Workshops vermittelt."
                        : "Product assessments, planning aids, organisational development, leadership and motivation topics - are could be topics for workshops with our experts."}
                    </p>
                    <div className="mt-8">
                      <div className="inline-flex rounded-md shadow">
                        <a
                          href="mailto:info@formgroup.io?subject=Website%20Anfrage%20Workshop:%20"
                          className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-brown-light"
                        >
                          {this.props.lang === "de"
                            ? "Workshop anfragen"
                            : "Request workshop"}
                          <MailIcon
                            className="-mr-1 ml-3 h-5 w-5 text-brown-dark"
                            aria-hidden="true"
                          />
                        </a>
                      </div>
                    </div>

                    <div>
                      <p className="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-10">
                        formgroup Agent
                      </p>
                      <div className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                        <div className="flex flex-wrap items-start justify-between">
                          <div className="flex items-center space-x-4 lg:space-x-6">
                            <img
                              className="w-16 h-16 object-cover rounded-full"
                              src={hugoSchweers}
                              alt="Hugo Schweers - formgroup expert"
                            />
                            <div className="leading-6 space-y-1 text-white">
                              <h3 className="font-semibold text-md">Hugo Schweers</h3>
                              <p className="text-sm font-regular text-white">
                                {this.props.lang === "de"
                                  ? "35+ Jahre Erfahrung im Agrobusiness"
                                  : "35+ years of experience in agribusiness"}
                              </p>
                              <a
                                href="mailto:hugo.schweers@formgroup.io?bcc=info@formgroup.io&subject=Website%20Anfrage:%20"
                                className="flex items-center justify-center w-24 px-2 py-2 text-base font-medium rounded-md shadow-sm text-white bg-green-light hover:bg-green-medium"
                              >
                                {this.props.lang === "de"
                                  ? "Kontakt"
                                  : "Contact"}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </>
    );
  }
}
