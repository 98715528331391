import React from "react";

import {
  ThumbUpIcon,
  BeakerIcon,
  CubeIcon,
  CogIcon,
  LibraryIcon,
  CheckIcon,
} from "@heroicons/react/solid";

import iconKinvara from "../../image/svg/ICON_fg_kinvara.svg";

import ImgKinvara from "../../image/jpg/img_kinvara.jpg";

import IconGridient from "../../image/svg/ICON_fg_grassland.svg";

// import SteckbriefKinvara from "../../image/pdf/Produktsteckbrief_Kinvara.pdf";

import People from "../people";

const summary = [
  {
    id: 1,
    contentDe: "Clopyralid 28g/l, Fluroxypyr 50g/l, MCPA 233g/l",
    contentEn: "Clopyralid 28g/l, Fluroxypyr 50g/l, MCPA 233g/l",
    target: "",
    href: "",
    highlightDe: "Mikroemulsion (ME)",
    highlightEn: "Mikroemulsion (ME)",
    icon: BeakerIcon,
    iconBackground: "bg-green-lighter",
    textColor: "text-green-lighter",
  },
  {
    id: 2,
    contentDe:
      "Wiesen und Weiden",
    contentEn:
      "Meadows and pastures",
    target: "",
    href: "",
    highlightDe: "Winter– und Sommergetreide",
    highlightEn: "Winter and summer cereals",
    icon: LibraryIcon,
    iconBackground: "bg-green-lighter",
    textColor: "text-green-lighter",
  },
  {
    id: 3,
    contentDe: "MOA: Auxine",
    contentEn: "MOA: Auxins",
    target: "",
    href: "",
    highlightDe: "Resistenzgruppe: 3x4(O)",
    highlightEn: "Resistance group: 3x4(O)",
    icon: ThumbUpIcon,
    iconBackground: "bg-green-lighter",
    textColor: "text-green-lighter",
  },
  {
    id: 4,
    contentDe: "008450-00 - Zulassung bis 31.10.2024",
    contentEn: "A008450-00 - Approval until 31.10.2024",
    target: "",
    href: "",
    highlightDe: "",
    highlightEn: "",
    icon: CogIcon,
    iconBackground: "bg-green-lighter",
    textColor: "text-green-lighter",
  },
  {
    id: 5,
    contentDe: "5,0l und 10,0l",
    contentEn: "5.0l and 10.0l",
    target: "",
    href: "",
    highlightDe: "",
    highlightEn: "",
    icon: CubeIcon,
    iconBackground: "bg-green-lighter",
    textColor: "text-green-lighter",
  },
];

const advantage = [
  {
    descriptionDe: "Top Breitenwirkung durch 3 synergistische Wirkstoffe",
    descriptionEn: "Top broad effect thanks to 3 synergistic active ingredients",
  },
  {
    descriptionDe: "Bekämpft die wichtigsten Unkräuter im Grünland",
    descriptionEn: "Controls the most important weeds in grassland",
  },
  {
    descriptionDe: "Verträglich -  Schont die wertvollen Futtergräser",
    descriptionEn: "Compatible - protects the valuable forage grasses",
  },
  {
    descriptionDe: "Vom Frühjahr bis zum letzten Schnitt im Herbst einsetzbar",
    descriptionEn: "Can be used from spring to the last cut in fall",
  },
  {
    descriptionDe: "Außerordentlich preiswert",
    descriptionEn: "Exceptionally good value",
  },
  {
    descriptionDe: "EIN Herbizid für Grünland und Getreide",
    descriptionEn: "ONE herbicide for grassland and cereals",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Kinvara(props) {
  return (
    <>
      <section>
        <div className="py-10 bg-gradient-to-r from-green-lighter to-green-medium sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center ">
                <div className="lg:py-24 mt-16 z-10">
                  <span className="px-3 py-1.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-lighter rounded-full">
                    {props.lang === "de" ? "Grünfläche" : "Grassland"}
                  </span>
                  <span className="px-3 py-1.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-yellow-light rounded-full">
                    {props.lang === "de" ? "Getreide" : "Creals"}
                  </span>
                  <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="block uppercase">Kinvara</span>
                  </h1>
                  <p className="mt-3 text-xl text-yellow-lighter font-extrabold sm:mt-5 sm:text-xl lg:text-2xl uppercase">
                    {props.lang === "de"
                      ? "Top Breitenwirkung durch 3 synergistische Wirkstoffe"
                      : "Top broad effect thanks to 3 synergistic active ingredients"}
                  </p>
                  <p className="mt-3 text-base text-white sm:mt-5 sm:text-xl lg:text-2xl">
                    {props.lang === "de"
                      ? "Kinvara - Breit und Stark im Grünland & Getreide"
                      : "Kinvara - Broad and strong in grassland & cereals"}
                  </p>
                  {/* <div className="mt-8">
                    <div className="inline-flex rounded-md shadow">
                      <a
                        href={SteckbriefKinvara}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex items-center justify-center px-5 py-3 border border-transparent uppercase text-base font-bold rounded-md text-white bg-yellow-lighter hover:bg-brown-dark"
                      >
                        {props.lang === "de"
                          ? "Download Produktsteckbrief"
                          : "Download Product Profile"}
                        <DocumentDownloadIcon
                          className="-mr-1 ml-3 h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="mt-24 -mb-32 lg:mt-52 lg:relative">
                <div className="mx-auto max-w-sm grid px-8">
                  <img
                    className="w-full z-40"
                    src={iconKinvara}
                    alt="Kinvara"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="relative bg-white">
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
              <img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                src={ImgKinvara}
                alt="creales portato salad rape Kinvara agroform"
              />
            </div>
          </div>
          <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
            <div className="lg:col-start-2 lg:pl-8">
              <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
                <h2 className="leading-6 text-green-light font-semibold tracking-wide uppercase">
                  Kinvara
                </h2>
                <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  {props.lang === "de" ? "Auf einen Blick" : "At a glance"}
                </h3>
                <div className="flow-root">
                  <ul className="mt-8">
                    {summary.map((event, eventIdx) => (
                      <li key={event.id}>
                        <div className="relative pb-8">
                          {eventIdx !== summary.length - 1 ? (
                            <span
                              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex space-x-3">
                            <div>
                              <span
                                className={classNames(
                                  event.iconBackground,
                                  "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                                )}
                              >
                                <event.icon
                                  className="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                              <div>
                                <p className="text-sm text-black">
                                  {props.lang === "de"
                                    ? event.contentDe
                                    : event.contentEn}
                                  <a
                                    href={event.href}
                                    className="font-medium text-gray-900"
                                  >
                                    {event.target}
                                  </a>
                                </p>
                              </div>
                              <div
                                className={classNames(
                                  event.textColor,
                                  "text-right font-bold text-sm whitespace-nowrap"
                                )}
                              >
                                <p>
                                  {props.lang === "de"
                                    ? event.highlightDe
                                    : event.highlightEn}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Kinvara">
        <div className="bg-white md:px-16 my-12 md:my-24">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
            <div>
              <a
                href="/"
                className="inline-flex items-center text-white bg-gray-100 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
              >
                <span className="px-3 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-green-lighter rounded-full">
                  {props.lang === "de" ? "Grünland" : "Grassland"}
                </span>
                <span className="px-3 py-0.5 mx-1 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-yellow-light rounded-full">
                  {props.lang === "de" ? "Getreide" : "Creals"}
                </span>
              </a>

              <h2 className="mt-2 text-base font-semibold text-green-lighter uppercase tracking-wide">
                Kinvara
              </h2>
              <p className="mt-2 text-2xl font-extrabold text-gray-900">
                {props.lang === "de"
                  ? "Breit und Stark im Grünland & Getreide"
                  : "Broad and strong in grassland & cereals"}
              </p>
              <div className="grid place-items-center mt-6">
                <img alt="Creals" src={IconGridient} className="w-48" />
              </div>
            </div>
            <div className="mt-24 lg:mt-16 lg:col-span-2 ">
              <dl className="space-y-10 sm:space-y-4 items-start lg:grid grid-cols-2 grid-rows-2 gap-8">
                {advantage.map((content) => (
                  <div key={content.descriptionEn} className="relative">
                    <dt>
                      <CheckIcon
                        className="absolute h-6 w-6 text-green-lighter"
                        aria-hidden="true"
                      />
                      <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                        {props.lang === "de"
                          ? content.descriptionDe
                          : content.descriptionEn}
                      </p>
                    </dt>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </section>

      <People lang={props.lang} />
    </>
  );
}
